<template>
  <NuxtLink
    class="flex items-center text-base leading-4 gap-x-2 text-neutral btn-xs px-0 font-sans font-semibold"
    :to="navObjectUsed ? navigate : { name: navigate }"
  >
    <Icon name="ph:arrow-left-bold" width="24" height="24" />

    <slot> back</slot>
  </NuxtLink>
</template>

<script setup lang="ts">
  const props = defineProps<{
    navigate: string | Record<string, any>;
  }>();

  const navObjectUsed = computed(() => typeof props.navigate === "object");
</script>
